(() => {
  if (window.customElements.get('pr-pledge-banner')) return;

  class PrideraiserPledgeBanner extends HTMLElement {
    get variant(): string | null {
      return this.getAttribute('variant');
    }
    set variant(value: string | null) {
      if (value) {
        this.setAttribute('variant', value);
      } else {
        this.removeAttribute('variant');
      }
    }

    get avatar(): string | null {
      return this.querySelector('.avatar')?.getAttribute('src') ?? null;
    }
    set avatar(value: string | null) {
      if (!this.querySelector('.avatar')) {
        const avatar = document.createElement('img');
        avatar.classList.add('avatar');
        const avatarWrapper = document.createElement('span');
        avatarWrapper.classList.add('avatar__wrapper');
        avatarWrapper.append(avatar);
        this.prepend(avatarWrapper);
      }
      if (value) {
        this.querySelector('.avatar')?.setAttribute('src', value);
        if (value.startsWith('data:image')) {
          this.querySelector('.avatar__wrapper')?.classList.remove(
            'avatar__wrapper--custom'
          );
        } else {
          this.querySelector('.avatar__wrapper')?.classList.add(
            'avatar__wrapper--custom'
          );
        }
      } else {
        this.querySelector('.avatar')?.removeAttribute('src');
        this.querySelector('.avatar__wrapper')?.classList.remove(
          'avatar__wrapper--custom'
        );
      }
    }

    get details(): string | null {
      return this.querySelector('.details')?.textContent ?? null;
    }
    set details(value: string | null) {
      if (!this.querySelector('.details')) {
        const details = document.createElement('span');
        details.classList.add('details');
        this.append(details);
      }
      if (value) {
        this.querySelector('.details')!.textContent = value;
      } else {
        this.querySelector('.details')!.textContent = '';
      }
    }

    get action(): string | null {
      return this.querySelector('.actions a')?.getAttribute('href') ?? null;
    }
    set action(value: string | null) {
      if (!this.querySelector('.actions a')) {
        const action = document.createElement('a');
        action.classList.add('button');
        action.dataset.size = 'small';
        action.dataset.color = 'white';
        action.textContent = 'Join Them!';
        const actionWrapper = document.createElement('span');
        actionWrapper.classList.add('actions');
        actionWrapper.append(action);
        this.append(actionWrapper);
      }
      if (value) {
        this.querySelector('.actions a')!.setAttribute('href', value);
      } else {
        this.querySelector('.actions a')!.removeAttribute('href');
      }
    }

    connectedCallback() {
      ['avatar', 'details', 'action'].forEach((attr) => {
        if (this.hasAttribute(attr)) {
          (this as any)[attr] = this.getAttribute(attr);
          this.removeAttribute(attr);
        }
      });
      this.addEventListener('animationend', () => {
        if (this.matches('.dismissed')) {
          requestAnimationFrame(() => {
            this.remove();
          });
        }
      });
    }
  }

  window.customElements.define('pr-pledge-banner', PrideraiserPledgeBanner);
})();
